import React from 'react';
import moment from 'moment';
import CabPayLogo from './cabpay_logo.svg';
import threeCab from '../images/3cab.webp';
import html2pdf from 'html2pdf.js';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Debug } from './Debug';
import cabvisionimage from '../images/cl.webp';
import { useState, useRef } from 'react'
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import engb from 'date-fns/locale/en-GB';
registerLocale('engb', engb)

const LookUpReceipt = () => {

    const [date, setDate] = useState(new Date());

    const [ applePay , setApplePay ] = useState(true);

    let receiptDetails = useRef({status: "initialized"});

    let errorStatus = useRef();

    const [submitted, setSubmitted] = useState(false);

    async function getReceipt() {
        
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/process`, {
            first_name: submittedFormObject.current.first_name,
            last_name: submittedFormObject.current.last_name,
            email: submittedFormObject.current.email,
            applePayButton: submittedFormObject.current.applePayButton,
            transaction_total_amount: submittedFormObject.current.transaction_total_amount,
            first_six: submittedFormObject.current.first_six,
            last_four: submittedFormObject.current.last_four,
            txn_time: submittedFormObject.current.date_picker
          })
          .then(function (response) {

            receiptDetails.current = response.data;

            return response.data;
          })
          .catch(function (error) {
            
            if(error) {
                errorStatus.current = true;   
            }

          });

        // console.log(submittedFormObject.current);

        setSubmitted(true);

        var element = document.getElementById('element-to-print');

        var opt = { filename: `Taxi Receipt ${moment(receiptDetails.current.transaction_date).format('YY-MM-DD')}.pdf`};

        html2pdf(element, opt);

    } 
        
    let submittedFormObject = useRef();

    const Fieldset = ({ name, label, secondLabel, x, noLabel, textArea, ...rest }) => (
        <React.Fragment>
            {
                !noLabel && <label className={`block text-md font-medium text-gray-700 ${ secondLabel ? "pb-2 mb-2":""}`} htmlFor={name}>{label}</label>
            }
            

            { x &&
                <>
                    <label className="text-red-700 font-bold pb-4">Please note that this must be the exact transaction amount (not rounded)</label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <span className="text-gray-500 sm:text-sm">
                            £  
                        </span>
                        </div>
                        <Field as={`${ textArea ? "textarea" : "input"}`} rows="4" cols="50" 
                        className="border focus:ring-indigo-500 py-2 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md" 
                        id={name} name={name} {...rest} />
                    </div>
                </>
            }

            {!x && 
                <Field as={`${ textArea ? "textarea" : "input"}`} rows="4" cols="50" className="py-2 px-3 border mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md" id={name} name={name} {...rest} />
            }

            {
                secondLabel && <label className="mt-2 block text-md font-medium text-red-700 pb-2"><b> - If you paid by Apple Pay, please enter your Apple Card Number instead. To find this please see: <a href="https://support.apple.com/en-us/HT212269" target="_blank">https://support.apple.com/en-us/HT212269 <span className="animate-pulse">&#8599;</span></a></b></label>
            }
            
            <ErrorMessage name={name}>
                { msg => <div style={{ color: 'red' }}>{msg}</div> }
            </ErrorMessage>

        </React.Fragment>
    );

    const r = receiptDetails.current;
    
    let switcher = "initialized";

    if(receiptDetails.current.status === 'initialized') {
        switcher = "initialized";
    } else if(Object.keys(receiptDetails.current).length === 16) {
        switcher = "noResult";
    } else if(Object.keys(receiptDetails.current).length === 18) {
        switcher = "result";
    }

    let Ingenico = false;
    let Payworks = false;

    if(typeof(receiptDetails.current.itr_txn_no) === 'object') {
        Payworks = true;
    } else if(typeof(receiptDetails.current.itr_txn_no) !== 'object') {
        Ingenico = true;
    }

    const entryMethods = {
        1 : "Barcode",
        2 : "Keyed",
        3 : "Swiped",
        4 : "Smartcard",
        5 : "Smartkey",
        6 : "ICC",
        7 : "Auto",
        8 : "Keyed not present",
        9 : "Unknown"
    }

    return (
        <>
                
                {
                    submitted !== true && (
                        <>
                            <div className="max-w-4xl mx-auto">
                                <img className="mx-auto my-2" src={cabvisionimage} width={200}/>
                                <h1 className="text-center my-4">Receipt app</h1>
                                <Formik
                                    initialValues={{
                                        applePayButton: true
                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                        applePayButton: Yup.boolean('Select this checkbox please'),
                                        first_name: Yup.string().required('You must enter a First Name'),
                                        last_name: Yup.string().required('You must enter a Last Name'),
                                        email: Yup.string().email('Invalid email address').required('Email Address is Required'),
                                        last_four: Yup.string().min(4, 'You must enter at least four characters').max(4, 'You can only enter a maximum of four characters').required('You must enter the last four digits of your payment method'),
                                        first_six: Yup.string().when("applePayButton", {
                                            is: (aCheckbox) => aCheckbox === false,
                                            then: Yup.string().min(6, 'You must enter at least six characters').max(6, 'You can only enter a maximum of six characters').required('You must enter the first six digits of your payment method')
                                        }),
                                        transaction_total_amount: Yup.number().min(0).required('You must enter the total amount'),
                                        date_picker: Yup.string().required('You must enter a Date/Time')
                                        })
                                    }
                                    onSubmit={values => {
                                        submittedFormObject.current = values;

                                        if(submittedFormObject.current.applePayButton == true) {
                                            delete submittedFormObject.current.first_six;
                                        }

                                        getReceipt();
                                    }}
                                >
                                { ({isSubmitting, handleReset, errors, values}) => (

                                    <>
                                        <Form className="mb-6">
                                            <div className="shadow overflow-hidden sm:rounded-md">                  
                                                <div className="px-4 py-5 bg-white sm:p-6">
                                                <div 
                                                className="grid grid-cols-4 gap-6"
                                                >

                                                <div className="field col-span-6 sm:col-span-4">
                                                    <Fieldset
                                                    name="first_name"
                                                    type="text"
                                                    label="First Name"
                                                    placeholder="First Name"
                                                    />
                                                </div>

                                                <div className="field col-span-6 sm:col-span-4">
                                                    <Fieldset
                                                    name="last_name"
                                                    type="text"
                                                    label="Last Name"
                                                    placeholder="Last Name"
                                                    />
                                                </div>

                                                <div className="field col-span-6 sm:col-span-4">
                                                    <Fieldset
                                                    name="email"
                                                    type="email"
                                                    label="Email"
                                                    placeholder="example@email.com"
                                                    />
                                                </div>

                                            <div className="border-4 border-black col-span-4 rounded-xl p-4 border-double">
                                            
                                            <h5 className="font-bold mb-3">Please select the Apple Pay button if you paid by Apple Pay:</h5>
                                    
                                            <div className="flex items-center mb-4">
                                                <Field 
                                                name="applePayButton"
                                                type="checkbox" 
                                                onClick={()=>setApplePay(!applePay)} 
                                                />
                                                <label className="font-bold ml-4">I paid by Apple Pay</label>
                                            </div>
                                            
                                            {
                                                applePay && (
                                                    <label className="col-span-4 mt-2 block text-md font-medium text-red-700 pb-2"><b> Please use the last four digits of your Apple Card Number. To find this please see: <a href="https://support.apple.com/en-us/HT212269" target="_blank">https://support.apple.com/en-us/HT212269 <span className="animate-pulse">&#8599;</span></a></b></label>
                                                )
                                            }

                                            {
                                                applePay !== true && (
                                                    <div className="field col-span-6 sm:col-span-4 mb-3">
                                                        <Fieldset
                                                        name="first_six"
                                                        type="text"
                                                        label="First 6 digits of card"
                                                        placeholder="000000"
                                                        />
                                                    </div>
                                                )
                                            }
                                            
                                            {
                                                !applePay && (
                                                    <div className="field col-span-6 sm:col-span-4">
                                                        <Fieldset
                                                        name="last_four"
                                                        type="text"
                                                        label="Last 4 digits of card"
                                                        placeholder="0000"
                                                        />
                                                    </div>
                                                )
                                            }
                                        
                                            {
                                                applePay && (
                                                    <div className="field col-span-6 sm:col-span-4">
                                                        <Fieldset
                                                        name="last_four"
                                                        type="text"
                                                        label="Last 4 digits of Apple Card"
                                                        placeholder="0000"
                                                        />
                                                    </div>
                                                )
                                            }

                                        </div>
                                            
                                        <div className="field col-span-6 sm:col-span-4">
                                                <label className="block text-md font-medium text-gray-700">Transaction Date - <label className="text-red-700 font-bold pb-4">Please note that this must be accurate</label> </label>
                                                <DatePicker 
                                                dateFormat="P"
                                                name="date_picker"
                                                locale="engb"
                                                className="py-2 px-4 border border-gray-300 rounded-md"
                                                selected={date}
                                                onChange={(date) => {
                                                    setDate(date);
                                                    values.date_picker = moment(date).format("YYYY-MM-DD");
                                                }}
                                                />
                                               
                                            </div>

                                            <div className="field col-span-6 sm:col-span-4">
                                                <Fieldset
                                                x={true}
                                                name="transaction_total_amount"
                                                type="text"
                                                label="Total Amount"
                                                placeholder="00.00"
                                                />
                                            </div>

                                        <div className="col-span-4">
                                            {errors.applePayButton && <div style={{ color: 'red' }}>You must select your payment method</div>}
                                            {errors.first_name && <div style={{ color: 'red' }}>You must enter a First Name</div>}
                                            {errors.last_name && <div style={{ color: 'red' }}>You must enter a Last Name</div>}
                                            {errors.email && <div style={{ color: 'red' }}>You must enter a Valid Email</div>}
                                            {errors.last_four && <div style={{ color: 'red' }}>You must enter a Last Four</div>}
                                            {errors.first_six && <div style={{ color: 'red' }}>You must enter a First Six</div>}
                                            {errors.transaction_total_amount && <div style={{ color: 'red' }}>You must enter a Transaction Total Amount</div>}
                                            {errors.date_picker && <div style={{ color: 'red' }}>You must select a Date</div>}
                                        </div>
                                                
                                                {/* <div className="col-span-4">
                                                    <Debug />
                                                </div> */}
                                            
                                                </div>
                                            </div>
                                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                                
                                                {
                                                    isSubmitting === false && (
                                                        <button type="submit" className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                            Submit
                                                        </button>
                                                    )
                                                }

                                                {
                                                    isSubmitting === true && (
                                                        <button disabled={true} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                                            Loading...
                                                        </button>
                                                    )
                                                } 

                                                {   isSubmitting === true && (
                                                        <div className="z-50 modal opacity-100 pointer-events-none fixed w-full h-full top-0 left-0 flex items-center justify-center">
                                                            <div className="modal-overlay absolute w-full h-full bg-white opacity-100"></div>
                                                                <div className="modal-container fixed w-full h-full z-50 overflow-y-auto ">
                                                                    
                                                                    
                                                                    <img className="mx-auto my-2 pt-20" src={cabvisionimage} width={200}/>
                                                                    <div className="mt-20 bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
                                                                        <h1 className="font-bold text-center">Please wait whilst we attempt to fetch your receipt ...</h1>
                                                                    </div>
                                                                    <img className="mx-auto my-2 pt-4" src={threeCab} width={400}/>

                                                                    
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>

                                       
                                        </Form>
                                    </>
                                    
                                    )
                                }
                                </Formik>
                            </div>
                        </>
                    )
                }

                {
                    submitted === true && switcher === "noResult" && ( 
                        <>
                            <div className="flex flex-col mx-auto my-2 pt-16 items-center justify-between px-6">

                                <img className="mb-8" src={cabvisionimage} width={200}/>

                                <a href="https://receipts.cabvision.com/" class=" bg-blue-500 hover:bg-blue-400 text-white font-bold py-2 px-4 border-b-4 border-blue-700 hover:border-blue-500 rounded inline-flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                                    </svg>
                                    <span className="ml-2">Try Again</span>
                                </a>

                            </div>

                            <div className="mt-8 bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
                                <h1 className="font-bold text-center">Sorry your transaction has not been found, please check the details provided are correct and try again. <br/> If you paid by Apple Pay, please enter your Apple Card Number instead. To find this please see: <a href="https://support.apple.com/en-us/HT212269" target="_blank">https://support.apple.com/en-us/HT212269 <span className="animate-pulse">&#8599;</span></a>  </h1>
                            </div>
                            <img className="mx-auto my-2 pt-4" src={threeCab} width={400}/>
                        </>
                    )
                }

                {
                    errorStatus.current === true && ( 
                        <>
                            <img className="mx-auto my-2 pt-20" src={cabvisionimage} width={200}/>
                            <div className="mt-20 bg-blue-100 border-t border-b border-blue-500 text-blue-700 px-4 py-3" role="alert">
                                <h1 className="font-bold text-center">Please try again later</h1>
                            </div>
                            <img className="mx-auto my-2 pt-4" src={threeCab} width={400}/>
                        </>
                    )
                }

                {
                    submitted === true && switcher === "result" && (
                        <>
                            <div id="element-to-print" >
                                <div className="flex flex-col max-w-xs mx-auto mt-8 mb-2 border border-black p-4">
                                    <div className="mx-auto mb-4"><img src={CabPayLogo}/></div>
                                    <div className="mx-auto">Cabvision Network Ltd</div>
                                    <div className="mx-auto">171 Lukin Street, E1 0BN</div>
                                    <div className="mx-auto">+44 (207) 655 6970</div>
                                    <div className="mt-4 mx-auto">queries@cabvision.com</div>
                                    <div className="mb-4 mx-auto">www.cabvision.com</div>
                                    <div className="my-4 mx-auto">Driver {receiptDetails.current.driver_badge_no}</div>
                                    <div className="mx-auto font-bold text-lg text-center mt-4">CARDHOLDER RECEIPT</div>

                                   {
                                       Ingenico === true && (<>
                                            <div className="mx-auto mb-4">London Taxi Journey</div>
                                       </>)
                                   }

                                   {
                                       Payworks === true && (<>
                                            <div className="mx-auto mb-4">CabPay Payment</div>
                                        </>)
                                   }

                                    <div className="flex justify-between">
                                        <div >Fare</div>
                                        <div>£{receiptDetails.current.fare_amount}</div>
                                    </div>


                                    <div className="flex justify-between mb-4">
                                        <div >Tip</div>
                                        <div>£{receiptDetails.current.gratuity_amount}</div>
                                    </div>
                                          

                                    <div className="flex justify-between border-t-2 border-b-2 border-dashed py-4">
                                        <div className="flex items-end font-bold">Total</div>
                                        <div className="text-3xl">£{receiptDetails.current.transaction_total_amount}</div> 
                                    </div>

                                    <div className="flex justify-between mt-4">
                                        <div >Card</div>
                                        <div>{receiptDetails.current.card_scheme}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div >Account</div>
                                        <div>**** **** **** {receiptDetails.current.last_four}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div >Entry Mode</div>
                                        <div>{entryMethods[receiptDetails.current.entry_method]}</div>
                                    </div>
                                
                                    <div className="text-center my-2">Please retain receipt!</div>

                                    <div className="flex justify-between mt-2">
                                        <div >{moment(receiptDetails.current.transaction_time).format('DD/MM/YY')}</div>
                                        <div>{moment(receiptDetails.current.transaction_time).format('h:mm:ss')}</div>
                                    </div>
                                  
                                    <div className="flex justify-between mt-6">
                                        <div className="text-sm">Merchant ID</div>
                                      
                                        <div className="text-sm">**{receiptDetails.current.acquirer_merchant_no.slice(2)}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="text-sm">Terminal ID</div>
                                        <div className="text-sm">****{receiptDetails.current.terminal_id.slice(-4)}</div>
                                    </div>
                                    <div className="flex justify-between">
                                        <div className="text-sm ">Authorization</div>
                                        <div className="text-sm ">{receiptDetails.current.auth_code}</div>
                                    </div>
                                    
                                    <div className="text-center font-bold mt-2">Transaction approved.</div>
                                </div>
                            </div>

                            <div className="flex justify-center">
                                    <h4 className="text-center font-semibold">A PDF has been downloaded to your device.</h4>
                            </div>
                        </>
                    )
                }
        </>
    )
}
 
export default LookUpReceipt
