import {
  BrowserRouter as Router,
  Routes, 
  Route
} from "react-router-dom";
import './App.css';
import LookUpReceipt from "./components/LookUpReceipt";


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LookUpReceipt />}/>
      </Routes>
    </Router>
  );
}

export default App;
